
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		StackedBarChartFilterable,
	},
})
export default class CustomerAgingSummaryBarChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['customers', 'agents', 'dateAsOf'];

	public result: number[] = [];
	public xAxisData: string[] = [];
	public series: echarts.ECharts[] | any = [];

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get dateFormatted() {
		return [this.selectedAsOfDate];
	}

	public get expensiveHook() {
		const { selectedAsOfDate, selectedAgents, selectedCustomers } = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedAgents,
			selectedCustomers,
		]);
	}

	public async expensiveCalc() {
		// #1 task
		let pa = 0.1;
		let pb = 0;

		const tradeRef = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate)
			.customers(this.selectedCustomers);

		// #2 task
		pb += pa;
		pa = 0.1;

		const ivdnRef = FilteredDatabase.ref('customerIvDns')
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate)
			.customers(this.selectedCustomers);

		// #3 task
		pb += pa;
		pa = 0.1;

		const koRef = FilteredDatabase.ref('customerKos')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateAsOf(this.selectedAsOfDate)
			.numberRange('koDate', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.selectedAsOfDate],
			]);

		// #4 task
		pb += pa;
		pa = 0.1;

		const ivdnDD = await this._loadDimensionByPeriod(
			ivdnRef,
			'last30-120days',
			pa,
			pb,
			'amount',
		);

		// #5 task
		pb += pa;
		pa = 0.1;

		const koDD = await this._loadDimensionByPeriod(
			koRef,
			'last30-120days',
			pa,
			pb,
			'amount',
		);

		// #6 task
		pb += pa;
		pa = 0.1;

		const gainLossDD = await this._loadDimensionByPeriod(
			koRef,
			'last30-120days',
			pa,
			pb,
			'gainLoss',
		);

		// #7 task
		pb += pa;
		pa = 0.1;

		const tradeDD = await this._loadDimensionByPeriod(
			tradeRef,
			'last30-120days',
			pa,
			pb,
			'unappliedAmount',
		);

		this.xAxisData = ivdnDD.map((dd) => dd.text);

		// #8 task
		pb += pa;
		pa = 0.3;

		this.result = [];

		ivdnDD.forEach((dd, ddIndex) => {
			const amount =
				dd.sum -
				koDD[ddIndex].sum -
				gainLossDD[ddIndex].sum -
				tradeDD[ddIndex].sum;

			this.result.push(amount);
		});

		this.series = [
			{
				name: 'Total',
				data: this.result,
				type: 'bar',
			},
		];

		this.saveHistory('series', 'xAxisData');
	}
}
