
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class TotalOutstandingNumberDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['customers', 'agents', 'dateAsOf'];

	public amount: number = 0;

	public get expensiveHook() {
		const { selectedAsOfDate, selectedAgents, selectedCustomers } = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedAgents,
			selectedCustomers,
		]);
	}

	public async expensiveCalc() {
		let pa = 0.5;
		let pb = 0;

		const ivdnRef = FilteredDatabase.ref('customerIvDns')
			.customers(this.selectedCustomers)
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate);

		const koRef = FilteredDatabase.ref('customerKos')
			.customers(this.selectedCustomers)
			.agents(this.selectedAgents)
			.numberRange('koDate', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.selectedAsOfDate],
			]);

		const unappliedAmountRef = FilteredDatabase.ref('customerTrades')
			.customers(this.selectedCustomers)
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate);

		pb += pa;
		pa = 0.5;

		const ivdnRefHead = await ivdnRef.get();
		const koRefHead = await koRef.get();
		const unappliedAmountRefHead = await unappliedAmountRef.get();

		const gainLoss = await koRefHead.getSum('gainLoss');
		const ko = await koRefHead.getSum('amount');
		const ivdn = await ivdnRefHead.getSum('amount');
		const unappliedAmount = await unappliedAmountRefHead.getSum(
			'unappliedAmount',
		);

		this.amount = ivdn - ko - gainLoss - unappliedAmount;

		this.saveHistory('amount');
	}
}
