
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup } from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import moment from 'moment';

@Component({
  components: {
    TableFilterable,
  },
})
export default class CustomerAgingOverviewTable extends FilterWidget {
  public get permissionIds(): PermissionsGroup[] {
    return ['customers'];
  }

  public filterIds: Array<
      | 'date'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
  > = ['date', 'customers', 'agents'];

  public tableItems: any[] = [];
  public tableFields: any[] = [];

  public get exportFileName() {
    const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
    return 'Customer Aging Overview' + '_' + 'As Of ' + formattedDate;
  }

  public get dateFormatted() {
    return [this.selectedAsOfDate];
  }

  public get expensiveHook() {
    const { selectedAsOfDate, selectedAgents, selectedCustomers } = this;
    return JSON.stringify([
      selectedAsOfDate,
      selectedAgents,
      selectedCustomers,
    ]);
  }

  public async expensiveCalc() {
    const listContainer: string[][] = [];

    const tradeRef = FilteredDatabase.ref('customerTrades')
        .agents(this.selectedAgents)
        .dateAsOf(this.selectedAsOfDate, 'dueDate')
        .customers(this.selectedCustomers);

    const tradeCustomerList: string[] = [
      ...(await (await tradeRef.get()).getSet('code')),
    ];

    listContainer.push(tradeCustomerList);

    const ivdnRef = FilteredDatabase.ref('customerIvDns')
        .agents(this.selectedAgents)
        .dateAsOf(this.selectedAsOfDate, 'dueDate')
        .customers(this.selectedCustomers);

    const ivdnCustomerList: string[] = [
      ...(await (await ivdnRef.get()).getSet('code')),
    ];

    listContainer.push(ivdnCustomerList);

    const koRef = FilteredDatabase.ref('customerKos')
        .agents(this.selectedAgents)
        .customers(this.selectedCustomers)
        .dateAsOf(this.selectedAsOfDate)
        .numberRange('koDate', [
          ['>', Number.NEGATIVE_INFINITY],
          ['<=', this.selectedAsOfDate],
        ]);

    const koCustomerList: string[] = [
      ...(await (await koRef.get()).getSet('code')),
    ];

    listContainer.push(koCustomerList);

    const filteredCustomerList = listContainer.reduce((a, b) =>
        a.length > b.length ? a : b,
    );

    const tradeRef2 = FilteredDatabase.ref('customerTrades')
        .agents(this.selectedAgents)
        .dateAsOf(this.selectedAsOfDate)
        .customers(filteredCustomerList);

    const ivdnRef2 = FilteredDatabase.ref('customerIvDns')
        .agents(this.selectedAgents)
        .dateAsOf(this.selectedAsOfDate)
        .customers(filteredCustomerList);

    const koRef2 = FilteredDatabase.ref('customerKos')
        .agents(this.selectedAgents)
        .customers(filteredCustomerList)
        .dateAsOf(this.selectedAsOfDate)
        .numberRange('koDate', [
          ['>', Number.NEGATIVE_INFINITY],
          ['<=', this.selectedAsOfDate],
        ]);

    let pa = 0.25;
    let pb = 0;

    const ivdnDD = await this._loadDimensionByFilters(
        ivdnRef2,
        'customers',
        pa,
        pb,
        (reff, paa, pbb) =>
            this._loadDimensionByPeriod(reff, 'last30-120days', paa, pbb, 'amount'),
    );

    pb += pa;
    pa = 0.25;

    const koDD = await this._loadDimensionByFilters(
        koRef2,
        'customers',
        pa,
        pb,
        (reff, paa, pbb) =>
            this._loadDimensionByPeriod(reff, 'last30-120days', paa, pbb, 'amount'),
    );

    pb += pa;
    pa = 0.25;

    const gainLossDD = await this._loadDimensionByFilters(
        koRef2,
        'customers',
        pa,
        pb,
        (reff, paa, pbb) =>
            this._loadDimensionByPeriod(
                reff,
                'last30-120days',
                paa,
                pbb,
                'gainLoss',
            ),
    );

    pb += pa;
    pa = 0.25;

    const tradeDD = await this._loadDimensionByFilters(
        tradeRef2,
        'customers',
        pa,
        pb,
        (reff, paa, pbb) =>
            this._loadDimensionByPeriod(
                reff,
                'last30-120days',
                paa,
                pbb,
                'unappliedAmount',
            ),
    );

    const periodMap =
        ivdnDD.length > 0
            ? ivdnDD[0].subDimension!.map((subDD) => subDD.text)
            : [];

    this.tableFields = [
      { key: 'company_name', sortable: true, stickyColumn: true },
      ...periodMap.map((period) => ({
        key: period,
        // label: dd.text,
        sortable: true,
        formatter: TableItemFormatter.currency,
      })),
      {
        key: 'total',
        sortable: true,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'agent',
        sortable: true,
      },
    ];

    const cards = await (await ivdnRef.get()).getCards();

    const items =
        ivdnDD.length > 0
            ? ivdnDD.map((dd, ddIndex) => {
              const koSub = koDD[ddIndex].subDimension;
              const gainLossSub = gainLossDD[ddIndex].subDimension;
              const tradeSub = tradeDD[ddIndex].subDimension;

              const item: any = {
                total:
                    dd.sum -
                    koDD[ddIndex].sum -
                    gainLossDD[ddIndex].sum -
                    tradeDD[ddIndex].sum,
                company_name: dd.text,
                agent: cards[cards.findIndex((a) => {
                  return ivdnDD[ddIndex].value[0] === a.code;
                })].agent,
              };
              dd.subDimension!.forEach((sd, sdIndex) => {
                item[sd.text] =
                    sd.sum -
                    koSub![sdIndex].sum -
                    gainLossSub![sdIndex].sum -
                    tradeSub![sdIndex].sum;
              });
              return item;
            })
            : [];

    this.tableItems = items.filter((item) => item.total !== 0);

    this.saveHistory('tableItems', 'tableFields');
  }
}
