
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PieChartFilterable from '@/components/reports-v2/components/filterables/PieChartFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { DimensionMap, PermissionsGroup } from '@/store/models.def';

@Component({
	components: {
		PieChartFilterable,
	},
})
export default class UnAppliedAmountPieChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public filterIds: Array<
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateRange', 'customers', 'agents'];

	public pieDatas: Array<{ name: string; value: number }> = [];

	public get expensiveHook() {
		const { selectedDateRange, selectedAgents, selectedCustomers } = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAgents,
			selectedCustomers,
		]);
	}

	public async expensiveCalc() {
		const codeList: string[] = ['PM', 'CN', 'CT', 'DP'];

		const nameList: string[] = [
			'Payment',
			'Credit Note',
			'Contra',
			'Deposit',
		];
		const map: DimensionMap[] = codeList.map((code, index) => ({
			filterType: 'string',
			filterKey: 'docType',
			value: [code],
			text: nameList[index],
		}));

		const ref = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateAsOf(this.selectedAsOfDate);

		const dimensionDatas = await this._loadDimension(
			ref,
			map,
			1,
			0,
			'unappliedAmount',
		);

		this.pieDatas = dimensionDatas.map((dd) => ({
			name: dd.text,
			value: dd.sum,
		}));

		this.saveHistory('pieDatas');
	}
}
