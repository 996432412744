
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import { roundToFixed } from '@/util/number';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class TotalOverdueNumberDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['customers', 'agents', 'dateAsOf'];

	public amount: number = 0;
  public amountNum: any = '';

  public thousandFormatter(x) {
    return gdbx.currencySymbol + ' ' + Math.ceil(x).toLocaleString('en-US');
  }

	public get expensiveHook() {
		const { selectedAsOfDate, selectedAgents, selectedCustomers } = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedAgents,
			selectedCustomers,
		]);
	}

	public async expensiveCalc() {
		const ivdnRef = FilteredDatabase.ref('customerIvDns')
			.customers(this.selectedCustomers)
			.agents(this.selectedAgents);

		const asOfIvdnRef = ivdnRef.clone().dateAsOf(this.selectedAsOfDate);
		const dueIvdnRef = asOfIvdnRef.clone().numberRange('dueDate', [
			['>', Number.NEGATIVE_INFINITY],
			['<=', this.selectedAsOfDate],
		]);

		const koRef = FilteredDatabase.ref('customerKos')
			.customers(this.selectedCustomers)
			.agents(this.selectedAgents);

		const asOfKoRef = koRef.clone().numberRange('koDate', [
			['>', Number.NEGATIVE_INFINITY],
			['<=', this.selectedAsOfDate],
		]);
		const dueKoRef = asOfKoRef.clone().numberRange('dueDate', [
			['>', Number.NEGATIVE_INFINITY],
			['<=', this.selectedAsOfDate],
		]);

		const tradeRef = FilteredDatabase.ref('customerTrades')
			.customers(this.selectedCustomers)
			.agents(this.selectedAgents);
		const asOfTradeRef = tradeRef.clone().dateAsOf(this.selectedAsOfDate);

		let pa = 0.45;
		let pb = 0;

		const ivdn = await (await asOfIvdnRef.get()).getSum('amount');
		const ko = await (await asOfKoRef.get()).getSum('amount');
		const gainLoss = await (await asOfKoRef.get()).getSum('gainLoss');
		const unappliedAmount = await (await asOfTradeRef.get()).getSum(
			'unappliedAmount',
		);

		pb += pa;
		pa = 0.45;

		const dueIvdn = await (await dueIvdnRef.get()).getSum('amount');
		const dueKo = await (await dueKoRef.get()).getSum('amount');
		const dueGainLoss = await (await dueKoRef.get()).getSum('gainLoss');

		const totalOutstanding = ivdn - ko - gainLoss - unappliedAmount;
		const totalDue = dueIvdn - dueKo - dueGainLoss - unappliedAmount;

		this.generateLoadingText(1);

  this.amountNum = totalOutstanding !== 0
        ? this.thousandFormatter(totalDue) + ' / ' + this.thousandFormatter(totalOutstanding)
        : 0;

		this.amount =
			totalOutstanding !== 0
				? roundToFixed((totalDue / totalOutstanding) * 100, gdbx.numDecimal)
				: 0;

		this.saveHistory('amount');
	}
}
