
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BasePageContainer from '@/components/reports-v2/pages/BasePageContainer.vue';
import moment from 'moment';
import PageIndicators from '@/components/reports-v2/components/pages/PageIndicators.vue';
import TitlePageIndex from '@/components/reports-v2/components/pages/TitlePageIndex.vue';
import AgentsListFilter from '@/components/reports-v2/components/filters/AgentsListFilter.vue';
import CustomerListFilter from '@/components/reports-v2/components/filters/CustomerListFilter.vue';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import AutoResizeSticky from '@/components/AutoResizeSticky.vue';
import LastSync from '@/components/LastSync.vue';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import gdbx from '@/store/modules/gdbx';

import Page from './Page.vue';
import PeriodDueCollectionPieChart from '../../components/codedWidgets/customer/PeriodDueCollectionPieChart.vue';
// tslint:disable-next-line:max-line-length
import TotalOutstandingNumberDisplayer from '../../components/codedWidgets/customer/TotalOutstandingNumberDisplayer.vue';
import TotalDueNumberDisplayer from '../../components/codedWidgets/customer/TotalDueNumberDisplayer.vue';
import TotalOverdueNumberDisplayer from '../../components/codedWidgets/customer/TotalOverdueNumberDisplayer.vue';
import CustomerOutstandingTreeMap from '../../components/codedWidgets/customer/CustomerOutstandingTreeMap.vue';
import CustomerOverdueTreeMap from '../../components/codedWidgets/customer/CustomerOverdueTreeMap.vue';
import CustomerUpcomingDueTable from '../../components/codedWidgets/customer/CustomerUpcomingDueTable.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import UnappliedAmountPieChart from '../../components/codedWidgets/customer/UnappliedAmountPieChart.vue';
import CustomerAgingSummaryBarChart from '../../components/codedWidgets/customer/CustomerAgingSummaryBarChart.vue';
import CustomerAgingOverviewTable from '../../components/codedWidgets/customer/CustomerAgingOverviewTable.vue';
import CustomerDueAgingTable from '../../components/codedWidgets/customer/CustomerDueAgingTable.vue';

@Component({
  components: {
    AutoResizeSticky,
    PageIndicators,
    TitlePageIndex,
    AgentsListFilter,
    CustomerListFilter,
    DateFilter,
    DateRangeFilter,
    StickyFilterContainer,
    LastSync,
    PeriodDueCollectionPieChart,
    TotalOutstandingNumberDisplayer,
    TotalDueNumberDisplayer,
    TotalOverdueNumberDisplayer,
    CustomerOutstandingTreeMap,
    CustomerUpcomingDueTable,
    UnappliedAmountPieChart,
    CustomerAgingSummaryBarChart,
    CustomerAgingOverviewTable,
    CustomerOverdueTreeMap,
    CustomerDueAgingTable,
  },
})
export default class Customer extends BasePageContainer {
  public selectedAgents: string[] = [];
  public selectedCustomers: string[] = [];
  public selectedAsOfDate: number = moment().endOf('day').valueOf();
  public selectedDateRange: [number, number] = [
    moment().startOf('year').valueOf(),
    moment().endOf('day').valueOf(),
  ];

  public get pages() {
    return [
      [
        'Total Outstanding',
        'Total Due',
        '% Overdue',
        'Customer Outstanding',
        'Upcoming Due',
        'Due Accounts Receivable',
        'Customer Aging Summary',
        'Customer Aging Overview',
      ],
      [
        'Customer Analysis',
        'Document Analysis (Invoice vs Payment Comparison)',
        'Customer Payment Analysis',
      ],
      // [
      // 	'Avg Value Per Transaction',
      // 	'Avg Unit Per Transaction',
      // 	'Trade Over Revenue',
      // 	'Un-applied Amount',
      // ],
      // ['Sales Period Comparison', 'Sales Trend'],
    ];
  }
  public mounted() {
    (this.$refs.stickyBottom as any).handleScroll();
  }
}
