
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PieChartFilterable from '@/components/reports-v2/components/filterables/PieChartFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';

@Component({
	components: {
		PieChartFilterable,
	},
})
export default class PeriodDueCollectionPieChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public filterIds: Array<
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateRange', 'customers', 'agents'];

	public pieDatas: Array<{ name: string; value: number; itemStyle: any }> = [];

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get expensiveHook() {
		const { selectedDateRange, selectedAgents, selectedCustomers } = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAgents,
			selectedCustomers,
		]);
	}

	public async expensiveCalc() {
		let pa = 0.25;
		let pb = 0;

		// totalDue: ivdns, dueDate >= d1, dueDate <= d2
		const head = await FilteredDatabase.ref('customerIvDns')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.numberRange('dueDate', [
				['>=', this.selectedDateRange[0]],
				['<=', this.selectedDateRange[1]],
			])
			.get();

		pb += pa;
		pa = 0.25;
		const totalDue = await head.getSum('amount');

		// totalCollected: kos, dueDate >= d1, dueDate <= d2, koDate <= d2

		pb += pa;
		pa = 0.25;
		const head2 = await FilteredDatabase.ref('customerKos')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.numberRange('dueDate', [
				['>=', this.selectedDateRange[0]],
				['<=', this.selectedDateRange[1]],
			])
			.numberRange('koDate', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.selectedDateRange[1]],
			])
			.get();

		pb += pa;
		pa = 0.25;
		const totalCollected = await head2.getSum('amount');

		const toBeCollected = totalDue - totalCollected;

		this.pieDatas = [
			{
				name: 'Total Collected',
				value: totalCollected,
				itemStyle: { color: GobiColor.TEAL },
			},
			{
				name: 'To Be Collected',
				value: toBeCollected,
				itemStyle: { color: GobiColor.PINK },
			},
		];
		this.saveHistory('pieDatas');
	}
}
