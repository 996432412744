
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TreeMapFilterable from '@/components/reports-v2/components/filterables/TreeMapFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { DimensionData, PermissionsGroup } from '@/store/models.def';
import * as echarts from 'echarts';

@Component({
	components: {
		TreeMapFilterable,
	},
})
export default class CustomerOutstandingTreeMap extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['customers', 'agents', 'dateAsOf'];

	public result: any = [];
	public series: echarts.ECharts[] | any = [];

	public get dateFormatted() {
		return [this.selectedAsOfDate];
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedCustomers,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedCustomers,
		]);
	}

	public async expensiveCalc() {
		const tradeRef = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateAsOf(this.selectedAsOfDate);

		const ivdnRef = FilteredDatabase.ref('customerIvDns')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers);

		const ivdnRef2 = ivdnRef.clone().dateAsOf(this.selectedAsOfDate);

		const koRef = FilteredDatabase.ref('customerKos')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers);

		const koRef2 = koRef.clone().numberRange('koDate', [
			['>', Number.NEGATIVE_INFINITY],
			['<=', this.selectedAsOfDate],
		]);

		let pa = 0.25;
		let pb = 0;

		const ivdnDD: DimensionData[] = await this._loadDimensionByFilters(
			ivdnRef2,
			'customers',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.25;

		const koDD: DimensionData[] = await this._loadDimensionByFilters(
			koRef2,
			'customers',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.25;

		const gainLossDD: DimensionData[] = await this._loadDimensionByFilters(
			koRef2,
			'customers',
			pa,
			pb,
			'gainLoss',
		);

		pb += pa;
		pa = 0.25;

		const unappliedAmountDD: DimensionData[] = await this._loadDimensionByFilters(
			tradeRef,
			'customers',
			pa,
			pb,
			'unappliedAmount',
		);

		this.result = [];

		ivdnDD.forEach((ivdn, index) => {
			this.result.push({
				name: ivdn.text,
				value:
					ivdn.sum -
					koDD[index].sum -
					gainLossDD[index].sum -
					unappliedAmountDD[index].sum,
			});
		});

		this.series = [];

		const stock = {
			name: 'Customer Outstanding Amount',
			type: 'treemap',
			roam: 'move',
			width: '100%',
			// drillDownIcon: '👆🏻',
			drillDownIcon: '',
			leafDepth: 1,
			label: {
				color: '#000',
			},
			itemStyle: {
				gapWidth: 1,
				borderWidth: 1,
			},
			data: this.result.length > 50 ? this.result.splice(50, this.result.length - 50) : this.result,
		};


		this.series = [stock];

		this.saveHistory('result', 'series');
	}
}
